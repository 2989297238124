/* Variables */
@import url('https://fonts.googleapis.com/css2?family=Merienda+One&family=Mate+SC&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Fira+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Playfair+Display:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
// Colors
$premium: #790000;
$dark: #0d0c0c;
$white: #ffffff;
$medium: #424242;
$lite: #98999C;
$blue: #2F8BA8;
$blue-lite: #DAEAFE;
$grey-lite: #CED0D6;
$lite-blue: #D3EDFB;
$bg-lite: #F7F8FC;
$intro: #D17000;
$menu: #F7F8FC;

$shadow-4: 0 4px 4px rgba(0,0,0,.15);
$shadow-10: 0 2px 10px rgba(0,0,0,.02);

// Fonts
$fira: 'Fira Sans', sans-serif;
$play: 'Playfair Display', sans-serif;

$basic: $fira;
$serif: $play;

// Placeholder
@mixin placeholder {
  &::-webkit-input-placeholder {
    @content; }
  &:-moz-placeholder {
    @content; }
  &::-moz-placeholder {
    @content; }
  &:-ms-input-placeholder {
    @content; } }

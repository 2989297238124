// About

.about {
  text-align: center !important;
  width: 100%;
  &-mob-100 {
    @include v-xs {
      clear: both;
      width: 100% !important;
      min-width: 100% !important; } }
  &-mob-block {
    @include v-xs {
      display: block !important; } }
  &__num {
    font-size: 2em; }
  &__first {
    color: $intro;
    font-size: 1.2em;
    font-weight: 500; }
  &-sub {
    font-size: 1.25em;
    line-height: 1.5;
    &.w-60 {
      @include v-xs {
        width: 100% !important;
        min-width: 100% !important; } } } }

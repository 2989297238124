// CTA

.product {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  margin: 40px;
  &_block {
    margin: 80px 0;
    @include v-xs {
      margin: 40px 0; } }
  @include v-sm {
    flex-flow: column nowrap; }
  &__icon {
    width: 64px;
    height: 64px;
    margin-bottom: 24px; }
  &__left {
    width: 32%;
    padding: 24px 5% 0;
    @include v-md {
      width: 46%;
      padding: 24px 2% 0; }
    @include v-sm {
      width: 90%;
      margin: 0 auto;
      padding: 0;
      text-align: center; }
    @include v-xs {
      width: 100%; } }
  &__left {
    text-align: left;
    @include v-sm {
      text-align: center; } }
  &__title {
    color: $premium;
    font-size: 1.05em;
    font-weight: 500;
    line-height: 1.35;
    min-height: 20vh !important;
    small {
      display: block;
      margin-top: 1em;
      color: $intro;
      font-size: .9em;
      font-weight: 400; } }
  &__text {
    margin: 24px auto 40px;
    font-size: 1em;
    line-height: 1.5;
    min-height: 22vh;
    strong {
      display: block;
      color: $medium;
      font-size: 0.95em;
      font-weight: 500 !important;
      padding: 1em 0;
      span {
        padding-right: .5em;
        font-size: 1.25em; } }
    @include v-sm {
      margin: 40px auto; }
    @include v-xs {
      margin: 20px auto;
      font-size: 1.2em; } } }

.form {
  width: 40%;
  margin: 0 auto 120px;
  @include v-sm {
    width: 80%;
    margin: 40px auto; }
  span {
    color: $premium;
    font-size: 0.85em;
    margin: 20px; }
  input,
  textarea {
    display: block;
    width: 100%;
    line-height: 48px;
    padding: 0 16px;
    margin: 20px 0;
    font-size: 1em;
    border: 2px solid $lite;
    background-color: $white;
    border-radius: 4px;
    border: 1px solid lighten($lite, 30%);
    outline: none !important;
    &[type='submit'] {
      background-color: $premium;
      color: $white;
      border: none;
      &:hover {
        background-color: $intro;
        cursor: pointer; } } }
  textarea {
    max-height: 160px; } }

// Title

.section-title {
  position: relative;
  width: 100%;
  margin: 0;
  padding: 72px 0 128px;
  text-align: center;
  font-size: 1.5em;
  font-weight: 400;
  @include v-sm {
    font-size: 1.4em;
    padding: 40px 0 80px; }
  @include v-xs {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    padding: 40px 0; }
  &:before {
    position: absolute;
    bottom: 64px;
    left: calc(50% - 40px);
    right: calc(50% - 40px);
    display: block;
    content: '';
    width: 80px;
    height: 1px;
    background-color: $premium;
    @include v-xs {
      bottom: 16px; } }
  &__default {
    color: $dark; }
  &__blue {
    margin-top: 24px;
    color: $premium;
    font-weight: 400;
    @include v-sm {
      margin-top: 0;
      font-size: .95em; } }
  &__white {
    color: white !important; } }

#contact_block {
  .section-title {
    @include v-xs {
      padding-top: 64px; } }
  .container.tс.w-50 {
    @include v-xs {
      min-width: 80% !important;
      margin-bottom: -24px; } } }

#download_block {
  .section-title {
    &__white {
      @include v-xs {
        margin-top: 40px;
        font-size: .85em !important; } } } }

// Page

.page {
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
  height: auto;
  padding: 80px 0 2vh;
  min-height: 100vh;
  background-color: lighten($lite,50%);
  .section-title {
    padding: 72px 0 80px;
    &:before {
      bottom: 40px; } }
  .container {
    padding: 0;
    margin: 0 auto; }
  &__navigation {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: center;
    height: 100px; }
  &__content {
    width: 100%;
    padding: 10vh 10vw 15vh;
    line-height: 1.75em;
    font-size: 1.1em;
    border-top: 1px solid #eaeaea;
    h1,h2,h3,h4,h5,h6 {
      font-weight: bold; }
    h1 {
      font-size: 1.4em; }
    h3 {
      font-size: 1.2em; } }
  &__header {
    width: 100%;
    padding: 0 60px;
    background-size: cover;
    background-blend-mode: multiply; }
  &__title {
    width: 50%;
    padding: 0;
    color: $dark;
    font-size: 1.75em;
    font-weight: 500; }
  &__pagination {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 50%; }
  &__link {
    padding-left: 10px;
    color: $medium;
    font-size: .95em;
    font-weight: 400;
    transition: all ease-out .3s;
    &--active,
    &--row {
      color: $premium;
      &:hover {
        color: $intro; } } } }

// Reviews

#reviews_block {
  padding-bottom: 80px;
  background-color: $white; }

.review {
  blockquote {
    padding: 0; }
  &__link {
    margin: 60px auto;
    text-align: center;
    @include v-sm {
      margin: 0 auto; }
    @include v-xs {
      .btn {
        width: 80%;
        margin: 0 auto -7%; } } }
  &__block {
    display: block;
    width: 70%;
    padding: 5% 0 0;
    @include v-md {
      padding: 4% 2% 0; }
    @include v-sm {
      width: 100%;
      padding: 0; } }
  &__right,
  &__left {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between; }
  &__right {
    .review {
      &__block {
        order: 2; }
      &__image {
        order: 1; } } }
  &__left {
    order: 1;
    .review {
      &__block {
        order: 1;
        @include v-sm {
          order: 2; } }
      &__image {
        order: 2;
        @include v-sm {
          order: 1; } } } }
  &__image {
    display: block;
    height: 240px;
    width: 240px;
    margin: 7% 5% 5%;
    transform: translateY(60px);
    border-radius: 8px !important;
    object-fit: cover;
    @include v-sm {
      width: 100%;
      height: 200px;
      margin: -2% 5% -2%; }
    @include v-xs {
      margin-top: -5%; } }
  &__name {
    padding: 10% 5% 0;
    color: $premium;
    font-size: 2em;
    font-weight: 500;
    @include v-sm {
      padding: 5% 7.5% 0; }
    @include v-xs {
      padding: 10% 12% 0;
      font-size: 1.75em; } }
  &__position {
    display: block;
    margin: -8px 0 40px;
    color: $lite;
    font-size: 1.1em;
    font-family: $play;
    font-style: italic;
    @include v-xs {
      margin: 20px 0 40px; } }
  &__text {
    margin: 24px auto 40px;
    color: $medium;
    font-size: 1.25em;
    font-family: $play;
    font-style: italic;
    line-height: 2;
    @include v-sm {
      margin: -15px auto 40px; }
    @include v-xs {
      font-size: 1.05em; } } }

// Download

#download_block {
  background-color: $premium;
  padding-bottom: 80px; }

.download {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  @include v-sm {
    flex-flow: column nowrap; }
  &__link {
    width: 20%;
    margin: 0 1vw;
    @include v-sm {
      width: 50%;
      margin: 2% auto; }
    @include v-xs {
      width: 80%;
      margin: 0 auto 6%; } }
  &__img {
    width: 100%;
    height: auto;
    object-fit: cover; } }

.share {
  &__title {
    margin: 80px auto 40px;
    color: $white;
    font-size: 1.35em;
    font-weight: 300;
    @include v-sm {
      margin: 40px auto 25px; }
    @include v-xs {
      width: 70%;
      font-size: 1.15em;
      line-height: 1.5; } }
  &__link {
    margin: 40px 10px;
    @include v-sm {
      margin: 40px 15px 0; }
    &:hover {
      cursor: pointer; } }
  &__image {
    width: 32px;
    opacity: 1;
    transition: all ease-out .3s; } }

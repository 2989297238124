* {
  font-family: $basic; }

.ma {
  margin-left: auto !important;
  margin-right: auto !important; }
.mla {
  margin-left: auto !important; }
.mra {
  margin-right: auto !important; }

.obfc {
  object-fit: cover; }

.w-100 {
  width: 100% !important;
  width: 100% !important;
  min-width: 100% !important;
  max-width: 100% !important; }
.w-90 {
  width: 90% !important;
  min-width: 90% !important;
  max-width: 90% !important; }
.w-80 {
  width: 80% !important;
  min-width: 80% !important;
  max-width: 80% !important; }
.w-70 {
  width: 70% !important;
  min-width: 70% !important;
  max-width: 70% !important; }
.w-60 {
  width: 60% !important;
  min-width: 60% !important;
  max-width: 60% !important; }
.w-50 {
  width: 50% !important;
  min-width: 50% !important;
  max-width: 50% !important; }
.w-40 {
  width: 40% !important;
  min-width: 40% !important;
  max-width: 40% !important; }
.w-30 {
  width: 30% !important;
  min-width: 30% !important;
  max-width: 30% !important; }
.w-20 {
  width: 20% !important;
  min-width: 20% !important;
  max-width: 20% !important; }
.w-15 {
  width: 15% !important;
  min-width: 15% !important;
  max-width: 15% !important; }
.w-10 {
  width: 10% !important;
  min-width: 10% !important;
  max-width: 10% !important; }
.w-3 {
  width: 33.33333% !important;
  min-width: 33.33333% !important;
  max-width: 33.33333% !important; }
.w-4 {
  width: 25% !important;
  min-width: 25% !important;
  max-width: 25% !important; }

.w-100,
.w-90,
.w-80,
.w-70,
.w-60,
.w-50,
.w-40,
.w-30,
.w-20,
.w-10,
.w-3,
.w-4 {
  @include v-sm {
    width: 96% !important;
    margin-left: auto;
    margin-right: auto; } }

.wide {
  @include v-sm {
    min-width: 90% !important;
    margin-left: auto;
    margin-right: auto; } }

.s-10,
.s-20,
.s-30,
.s-40,
.s-50,
.s-60,
.s-70,
.s-80,
.s-90,
.s-100 {
  width: 100% !important;
  clear: both;
  display: block;
  @include v-sm {
    height: 30px !important; } }

.s-0 {
  width: 100% !important;
  clear: both;
  display: block;
  overflow: hidden;
  height: 0 !important; }

.s {
  &-10 {
    height: 10px; }
  &-20 {
    height: 20px; }
  &-30 {
    height: 30px; }
  &-40 {
    height: 40px; }
  &-50 {
    height: 50px; }
  &-60 {
    height: 60px; }
  &-70 {
    height: 70px; }
  &-80 {
    height: 80px; }
  &-90 {
    height: 90px; }
  &-100 {
    height: 100px; } }

.aic {
  align-items: center !important; }

.aifs {
  align-items: flex-start !important; }

.aife {
  align-items: flex-end !important; }

.jcc {
  justify-content: center !important; }

.jcfs {
  justify-content: flex-start !important; }

.jcfe {
  justify-content: flex-end !important; }

.jcsa {
  justify-content: space-around !important; }

.jcsb {
  justify-content: space-between !important; }

.center {
  justify-content: center !important; }

.tl {
  text-align: left !important; }

.tr {
  text-align: right !important; }

.tc {
  text-align: center !important; }

.r {
  &-16 {
    border-radius: 16px; }
  &-32 {
    border-radius: 32px; }
  &-24 {
    border-radius: 24px; }
  &-8 {
    border-radius: 8px; }
  &-4 {
    border-radius: 4px; } }

.rowrap {
  flex-flow: row wrap; }

.oh {
  overflow: hidden; }

.flex {
  display: flex; }

.h {
  &-100 {
    height: 100vh !important;
    @include v-sm {
      height: auto;
      padding-top: 20%; } }
  &-90 {
    height: 90vh;
    @include v-sm {
      height: auto;
      padding-top: 2%;
      padding-bottom: 2%; } }
  &-80 {
    height: 80vh;
    @include v-sm {
      height: auto;
      padding-top: 2%;
      padding-bottom: 2%; } }
  &-70 {
    height: 70vh;
    @include v-sm {
      height: auto;
      padding-top: 2%;
      padding-bottom: 2%; } }
  &-60 {
    height: 60vh;
    @include v-sm {
      height: auto;
      padding-top: 2%;
      padding-bottom: 2%; } }
  &-55 {
    height: 55vh;
    @include v-sm {
      height: auto;
      padding-top: 2%;
      padding-bottom: 2%; } }
  &-50 {
    height: 50vh;
    @include v-sm {
      height: auto;
      padding-top: 2%;
      padding-bottom: 2%; } }
  &-45 {
    height: 45vh;
    @include v-sm {
      height: auto;
      padding-top: 2%;
      padding-bottom: 2%; } }
  &-40 {
    height: 40vh;
    @include v-sm {
      height: auto;
      padding-top: 2%;
      padding-bottom: 2%; } }
  &-30 {
    height: 30vh;
    @include v-sm {
      height: auto;
      padding-top: 2%;
      padding-bottom: 2%; } }
  &-20 {
    height: 20vh;
    @include v-sm {
      height: auto;
      padding-top: 2%;
      padding-bottom: 2%; } }
  &-10 {
    height: 10vh;
    @include v-sm {
      height: auto;
      padding-top: 2%;
      padding-bottom: 2%; } } }

.upp {
  text-transform: uppercase; }

.ex-3 {
  overflow: hidden;
  height: 56px;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  @include v-md {
    height: 300px !important;
    text-overflow: none;
    min-height: 100%;
    padding-bottom: 0 !important; }
  @include v-sm {
    height: 200px !important;
    text-overflow: none;
    min-height: 50%;
    padding-bottom: 0 !important; }
  @include v-xs {
    height: 320px !important;
    text-overflow: none;
    min-height: 100%;
    padding-bottom: 0 !important; }
  &:hover {
    text-overflow: none;
    overflow: visible;
    height: auto; } }

.ex {
  overflow: hidden;
  height: 116px;
  display: -webkit-box;
  -webkit-line-clamp: 6;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  @include v-md {
    height: 300px !important;
    text-overflow: none;
    min-height: 100%;
    padding-bottom: 0 !important; }
  @include v-sm {
    height: 200px !important;
    text-overflow: none;
    min-height: 50%;
    padding-bottom: 0 !important; }
  @include v-xs {
    height: 320px !important;
    text-overflow: none;
    min-height: 100%;
    padding-bottom: 0 !important; }
  &:hover {
    overflow: visible;
    height: auto;
    text-overflow: none; } }

lh {
  &-1 {
    line-height: 1 !important; }
  &-105 {
    line-height: 1.05 !important; }
  &-125 {
    line-height: 1.25 !important; }
  &-15 {
    line-height: 1.5 !important; }
  &-175 {
    line-height: 1.75 !important; }
  &-2 {
    line-height: 2 !important; } }

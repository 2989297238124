// Survey

.survey {
  &__title,
  &__text {
    width: 56%;
    margin: 20px auto;
    padding: 0;
    color: $white;
    font-size: 1.1em;
    font-weight: 400;
    line-height: 1.5;
    @include v-sm {
      width: 76%;
      font-size: .9em;
      margin: 10px auto; } }
  &__text {
    width: 43%;
    color: $blue-lite;
    font-size: 0.85em;
    font-weight: 300;
    @include v-sm {
      width: 76%;
      margin-bottom: 40px; } }
  &__form {
    display: flex;
    justify-content: space-between;
    align-items: center;
    @include v-sm {
      width: 80%;
      margin: 0 auto;
      flex-flow: column nowrap;
      justify-content: center; } }
  &__input,
  &__submit {
    display: inline-flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 60px;
    margin: 0 15px;
    padding: 0 24px;
    color: $dark;
    font-size: 1em;
    font-weight: 400;
    font-family: $basic;
    border-radius: 8px;
    background-color: rgb(232,240,254);
    @include v-sm {
      margin: 10px 0; }
    @include placeholder {
      color: $dark;
      opacity: 1;
      transition: all ease-out .3s; }
    &:focus {
      @include placeholder {
        opacity: 0; } }
    &--position {
 }      // background: $white url(../../assets/img/icons/survey/position.svg) 20px center no-repeat
    &--industry {
      max-width: 25%;
      position: relative;
      appearance: none;
      color: #000;
      border: none;
      outline: none;
      padding: 0 40px 0 24px;
      z-index: 1;
      background: rgb(232,240,254) url(../../assets/img/icons/survey/down.svg) right 20px center no-repeat;
      background-size: 14px;
      @include v-sm {
        max-width: 100%; } }
    &--user {
 } }      // background: $white url(../../assets/img/icons/survey/user.svg) 20px center no-repeat
  &__submit {
    justify-content: center;
    padding: 0 24px;
    color: $white;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: .05em;
    background-color: $premium; } }

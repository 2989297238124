// Header

#top_block {
  position: fixed;
  width: 100%;
  height: 80px;
  background-color: white;
  box-shadow: 0 2px 4px rgba(0,0,0,.025);
  z-index: 1000; }

.hb-burger {
  display: none;
  @include v-sm {
    display: block; }
  span {
    position: fixed;
    right: 28px;
    width: 30px;
    height: 2px;
    background-color: $premium;
    transition: all ease-out .2s;
    &.one {
      top: 29px;
      transform: rotate(0); }
    &.two {
      top: 40px;
      right: 32px; }
    &.three {
      top: 51px;
      transform: rotate(0); } }
  &.opened {
    span {
      right: 26px;
      &.one {
        top: 40px;
        transform: rotate(-45deg); }
      &.two {
        width: 0; }
      &.three {
        top: 40px;
        transform: rotate(45deg); } } } }

.hb-header {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 80px; }
.hb-logo {
  &__link {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 80px;
    color: $dark; }
  @include v-sm {
    position: fixed;
    top: 0;
    left: 15px; }
  &__img {
    width: auto;
    height: 56px;
    object-fit: cover; }
  &__text {
    margin-left: 15px;
    padding: 0;
    color: $premium;
    font-size: 1.5em;
    font-weight: 500;
    @include v-xs {
      font-size: 1.2em; } } }

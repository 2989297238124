/* Base */

html {
  font-family: $basic;
  font-weight: 400;
  font-size: 1rem;
  font-smooth: always;
  -webkit-font-smoothing: antialised;
  scroll-behavior: smooth; }
body {
  margin: 0;
  padding: 0;
  color: $dark;
  font-size: 95%;
  background: $bg-lite;
  background-size: 100%;
  &.fix-mob {
    @include v-sm {
      overflow: hidden; } }
  a {
    text-decoration: none; }
  input,
  button {
    border: none;
    outline: none; } }

.site-content {
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between; }

.content {
  display: block;
  width: 100%;
  height: auto;
  margin: 0;
  padding: 0; }

.container {
  display: block;
  width: 96%;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  &.text-center {
    text-align: center; }
  @include v-xl {
    max-width: 1440px; }
  @include v-lg {
    max-width: 1200px; }
  @include v-md {
    max-width: 1024px; }
  @include v-sm {
    max-width: 768px; }
  @include v-xs {
    max-width: 100%; } }

section {
  br {
    @include v-xs {
      display: none !important; } } }

// Menu

.hb-menu {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 75%;
  height: 40px;
  .btn {
    &--premium {
      @include v-sm {
        width: 100%;
        height: 60px;
        margin-left: 0;
        border-radius: 0;
        font-size: 1.25em; } } }
  @include v-sm {
    position: fixed;
    display: block;
    top: 80px;
    left: 120vw;
    right: 0;
    width: 100%;
    height: 100vh;
    background-color: $dark;
    transition: all ease-out .4s;
    &.mob-menu {
      left: 0; } }
  &__link {
    display: inline-flex;
    align-items: center;
    height: 40px;
    margin-left: 48px;
    color: $dark;
    font-size: 1em;
    text-transform: uppercase;
    @include v-sm {
      display: block;
      width: 100%;
      height: 60px;
      margin-left: 0;
      padding: 0 24px;
      color: $premium;
      font-size: 1em;
      line-height: 58px;
      align-items: center;
      border-top: 1px solid darken($dark,5%); }
    &:hover,
    &.active {
      color: $premium; } } }

.page__navigation {
  .page__title {
    @include v-xs {
      width: auto;
      font-size: 1em; } }
  .page__pagination {
    @include v-xs {
      width: auto;
      padding-right: 8px; } } }

.page__header {
  @include v-xs {
    padding: 0 16px; } }

.header-pages {
  .hb-menu {
    .hb-menu__link {
      @include v-sm {
        display: block;
        width: auto;
        top: 12px;
        right: 8px;
        position: fixed;
        color: $dark;
        font-size: 1em;
        text-transform: uppercase;
        border: none; } } } }

.page__content {
  @include v-xs {
    padding: 4vh 5vw 6vh; }

  $lato: 'Lato', sans-serif; }
$mate: 'Mate SC', sans-serif;
$mont: 'Montserrat', sans-serif;
$open: 'Open Sans', sans-serif;
$pop: 'Poppins', sans-serif;
$robot: 'Roboto', sans-serif;
$meri: 'Merienda One', sans-serif;

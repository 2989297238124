// Footer

.footer {
  position: relative;
  background-color: $dark;
  padding-bottom: 24px;
  z-index: 1;
  @include v-sm {
    padding-top: 25px; }
  .partners {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
    height: 48px;
    height: 160px;
    @include v-sm {
      height: 100px;
      width: 80%;
      margin: 0 auto; }
    &__link {
      margin: 0 16px;
      img {
        width: 40px;
        transition: all ease-out .3s;
        @include v-sm {
          width: 32px; } } } } }
.menu {
  &--footer {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 20vh;
    @include v-sm {
      flex-flow: column nowrap;
      margin: 40px auto 32px; }
    @include v-xs {
      margin: 0 auto 32px; }
    &__text,
    &__link {
      margin: 0 20px 16px;
      color: $lite-blue;
      font-size: 1em;
      transition: all ease-out .3s;
      &:hover {
        color: $premium; }
      &.active {
        &:hover {
          color: $lite-blue; } } }
    &__text {
      margin-top: 2px;
      font-size: 0.975em;
      font-weight: 300;
      &:hover {
        color: $lite-blue; } } } }

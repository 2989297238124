// Intro

.intro {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  width: 100%;
  height: auto;
  padding: 23vh 0 2vh;
  min-height: 100vh;
  background: $intro url(../../assets/img/cover.png) center top no-repeat;
  background-size: cover;
  .btn {
    padding: 24px 40px;
    color: $premium;
    font-weight: 600;
    background-color: #FFECBC;
    box-shadow: 0 2px 16px $premium;
    &:hover {
      color: $white;
      background-color: $dark; }
    @include v-md {
      margin: 0 auto; } }
  @include v-xs {
    padding-top: 20vh; }
  &__title {
    padding: 0;
    width: 80%;
    margin: 0 auto;
    color: $white;
    font-size: 3.5em;
    font-weight: 500;
    line-height: 1.25;
    @include v-md {
      font-size: 4em; }
    @include v-sm {
      margin-top: 20px;
      font-size: 2.5em;
      line-height: 1.05; }
    @include v-xs {
      width: 80%;
      margin: 20px auto;
      font-size: 1.95em;
      line-height: 1.25; } }
  &__subtitle {
    padding: 0;
    color: $white;
    font-size: 1.75em;
    font-weight: 300;
    @include v-sm {
      font-size: 1.2em;
      line-height: 1.25; }
    @include v-xs {
      width: 80%;
      margin: 20px auto;
      line-height: 1.5; } }
  &__text {
    margin-bottom: 56px;
    padding: 0;
    color: $white;
    font-size: 1.5em;
    font-weight: 300;
    font-style: italic;
    @include v-sm {
      font-size: 1.15em; }
    @include v-xs {
      margin-bottom: 25px; } }
  &__smile {
    display: inline-block;
    position: relative;
    top: 2px;
    left: 4px;
    font-style: normal;
    animation: Smile infinite 2s;
    animation: Smile infinite 2s; } }

@keyframes Smile {
  from {
    transform: rotate(-360deg); }
  to {
    transform: rotate(0); } }
